















































































































































































































import { apiApproval, apiProductFileLists } from '@/api/productFile'
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging, debounce } from '@/utils/util'
import ExportData from '@/components/export-data/index.vue'

@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
  },
})
export default class productFileList extends Vue {

  apiProductFileLists = apiProductFileLists

  pager: RequestPaging = new RequestPaging()
  searchObj: any = {
    orderNum: '',
    productName: '',
    privateType: '',
    status: '',
  }

  examineText: any = ''

  tableData: any[] = []

  // 列表
  getCardList(page?: number) {
    page && (this.pager.page = page);
    this.pager.request({
      callback: apiProductFileLists,
      params: {
        ...this.searchObj,
      },
    })
  }

  // 重置搜索
  reset (): void {
    Object.keys(this.searchObj).map((key) => {
      this.$set(this.searchObj, key, '')
    })
    this.getCardList(1)
  }

  // 去编辑商城
  toCardEdit (id: number | any, type: string): void {
    this.$router.push({
      path: '/card/makeCard/productFileDetail',
      query: {
        id: id,
        type: type
      },
    })
  }

  //审批
  async toExamine (id: number | any, type: string) {
    const res = await apiApproval({
      product_profiles_id: id,
      status: type,
      description: this.examineText
    })
    if (res) {
      this.getCardList()
    }
  }

  closeExamine () {
    this.examineText = ''
  }

  created () {
    this.getCardList(1)
    console.log(this.tableData)
  }
}
